

(function(){

    //load paths
    const siteContent = document.getElementById("site_content");
    const about = document.getElementById("about");
    
    // let loader = `<div class="loading"><i class="fas fa-spinner fa-spin"></i></div>`;
    // siteContent.innerHTML = loader;


    let getContent = function(){
  
      fetch("https://randywalton.ca/headless-cms/api/collections/get/pollys_projects?token=3880e75496efb98ddc147d71f677f3")
      .then(response => response.json())
      .then(array => {
          //console.log(array.entries);
          buildProjects.init(array.entries);
       })
       .catch(error => { 
            console.log(error);

            siteContent.innerHTML = '<div class="error">Loading Error</div>';
       });
  
    };
    
    getContent();
  
  
    let buildProjects = (function () {
  
      let init = function (jsonObj) {
        let projects = jsonObj;
        //console.log(projects);
        _render(projects);
  
      };
  
      let _render = function (projects) {
        siteContent.innerHTML = '';
        projects.forEach(project => {
          //console.log(project.changes_overview);
  
          let active_state = project.status;
  
          if (active_state === "Published"){
            //create an article
            let myArticle = document.createElement('article');
            //add a class name
            myArticle.className = 'project_block';
  
            //Add the image
            let createImage = _getImage(project.project_image.path, project.url, project.title);
            myArticle.append(createImage); 

            //Add the titles
            let createTitle = _getTitle(project.title);
            let createSubTitle = _getSubTitle(project.subtitle);
            let createDeviceTitle = _getDeviceTitle(project.target_devices);
            myArticle.append(createTitle, createDeviceTitle, createSubTitle); 
  
            //Add the content
            let createContent = _getContent(project.idea_overview, project.testing_overview, project.changes_overview);
            myArticle.append(createContent); 

            //Add the buttons
            let createButton = _getButton(project.url);
            myArticle.append(createButton); 
  
            siteContent.appendChild(myArticle); //add article to main container
          }
  
        });
  
      };
  
      let _getImage = function (proj_img, proj_url, alt) {
        //build image
        let figure = document.createElement('figure');
        let img = document.createElement('img');
        let a = document.createElement('a');

        img.alt = alt;
        img.src = `https://randywalton.ca/headless-cms/api/cockpit/image?token=d5dcac729e3f9c619d58433ab26ae1&src=${proj_img}&w=800&o=true`;
        a.href = proj_url;

        a.append(img);
        figure.append(a);

        return figure;
      };

      let _getTitle = function (copy) {
        //build title
        let h2 = document.createElement('h2');
        h2.textContent = copy;
        return h2;
      };

      let _getSubTitle = function (copy) {
        //build title
        let h4 = document.createElement('h4');
        h4.textContent = copy;
        return h4;
      };

      let _getDeviceTitle = function (copy) {
        //build title
        let h3 = document.createElement('h3');
        h3.textContent = copy;
        return h3;
      };
  
      let _getContent = function (overview, testing, changes) {
  
        //console.log(content);

        const contentSection = document.createElement('section');
        
        const overviewTitle = document.createElement('h5');
        overviewTitle.textContent = 'Idea, Concept & Feedback:';
        const testTitle = document.createElement('h5');
        testTitle.textContent = 'Testing:';
        const changeTitle = document.createElement('h5');
        changeTitle.textContent = 'Changes:';

        const htmlOverview = document.createRange().createContextualFragment(overview);
        const htmlTesting = document.createRange().createContextualFragment(testing);
        const htmlChanges = document.createRange().createContextualFragment(changes);

        contentSection.append(overviewTitle, htmlOverview, testTitle, htmlTesting, changeTitle, htmlChanges);
  
        return contentSection;
  
      };

      let _getButton = function (proj_url) {
        //build button
        let button = document.createElement('a');
        //add a class name
        button.className = 'main_btn';
        button.textContent = 'Launch Project';
        
        button.setAttribute('href', proj_url);

        return button;
  
      };

      return {
        init: init
      };
  
    })();


    let getAbout = function(){

      fetch("https://randywalton.ca/headless-cms/api/singletons/get/pollys_about?token=3880e75496efb98ddc147d71f677f3")
      .then(response => response.json())
      .then(content => {
        //console.log(content);
  
        about.innerHTML = content.overview;
  
       })
       .catch(error => { 
            console.log(error);
            //siteContent.innerHTML = '<div class="error">Loading Error</div>';
       });
  
    };

    getAbout();
  
  })();
  